<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="pb-5 mb-5"
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        xs10
        md6
        lg4
      >
        <material-stats-card
          color="tertiary"
          icon="mdi-logout"
          title="Déconnexion"
          value="Merci"
          small-value=", à bientôt"
          sub-icon="mdi-timer"
          sub-icon-color="error"
          sub-text="Déconnexion automatique"
          sub-text-color="warning"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Router from '@/router/'

export default {
  name: 'Logout',

  mounted () {
    // redirect after 2,5s
    setTimeout(
      function () {
        // remove localstorage user then redirect to login
        localStorage.removeItem('user')
        localStorage.removeItem('session')
        Router.push({ path: 'login' })
      }
      , 2500)
  }
}
</script>

<style>
</style>
